var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5996c62788f4729d39be7b9edf8355bd8f987c93"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn: SENTRY_DSN || '',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
        'ibFindAllVideos',
        'jsReceiveMessages',
        'IntersectionObserver',
        'instantSearchSDKJSBridgeClearHighlight',
        'webkitExitFullScreen',
        'window.webkit.messageHandlers',
        /^Unexpected token u in JSON at position 0$/,
        /^The operation is insecure.$/,
        'localStorage',
        /^Route did not complete loading: \/_error$/,
        /^Unexpected token 'else'$/,
        /^Unexpected identifier$/,
        /^langDetector is not defined$/,
    ],
})
