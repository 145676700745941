import { ReactElement, useCallback, useEffect, useMemo, useReducer } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import loginReducer, { initialLoginState, LoginActions } from '@utils/loginReducer'
import LoginContext, { LoginContextProps } from '@components/context/LoginContext'

const AuthWrapper = ({ children }: { children: ReactElement }) => {
    const { t } = useTranslation('common')
    const router = useRouter()

    const referral = router.query.referral

    const [state, dispatch] = useReducer(loginReducer, initialLoginState)

    const getCustomerData = async (): Promise<{ customer: Customer | null; program: CustomerProgram | null } | null> => {
        const response = await fetch('/api/customer')
        if (response.ok) return await response.json()
        return null
    }

    const handleButtonLogin = useCallback(
        async ({ toAccount = true }: { toAccount: boolean }): Promise<void> => {
            if (state.isLogged && state.customer) {
                dispatch({ type: LoginActions.LoggedIn, customer: state.customer, redirectToAccount: toAccount })
            } else {
                dispatch({ type: LoginActions.Login, redirectToAccount: toAccount })
            }
        },
        [state.customer, state.isLogged]
    )

    // On page load/reload, make a check if user is logged.
    useEffect(() => {
        const checkIfLogged = async () => {
            const data = await getCustomerData()
            if (data?.customer && data?.program) {
                dispatch({ type: LoginActions.LoggedIn, customer: data.customer, redirectToAccount: false })
            } else if (!!referral) {
                dispatch({ type: LoginActions.Login })
            } else {
                dispatch({ type: LoginActions.Initial })
            }
        }
        checkIfLogged().catch(() => dispatch({ type: LoginActions.Initial }))
    }, [referral])

    // if state.redirectToAccount is set to true, useEffect is triggered, and make a redirect to page account.
    // Finally, redirectToAccount is set to false (LoginActions.Initial)
    useEffect(() => {
        if (state.customer && state.redirectToAccount) {
            router.push(`/${t('routes.account')}`).finally(() => dispatch({ type: LoginActions.Initial }))
        }
    }, [router, state, t])

    const contextValues: LoginContextProps = useMemo(
        () => ({
            state,
            dispatch,
            handleButtonLogin,
        }),
        [state, dispatch, handleButtonLogin]
    )

    return <LoginContext.Provider value={contextValues}>{children}</LoginContext.Provider>
}

export default AuthWrapper
