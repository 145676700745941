import { Translate } from 'next-translate'

const passwordChecks = (password: string, t: Translate): Array<string> => {
    const errors = []

    if (password.length < 8) errors.push(t('login.error.value.min-characters', { number: 8 }))
    if (password.length > 255) errors.push(t('login.error.value.max-characters', { number: 255 }))
    if (!/\d/.test(password)) errors.push(t('login.error.value.at-least-number'))
    if (!/[A-Z]/.test(password)) errors.push(t('login.error.value.at-least-uppercase'))
    if (!/[a-z]/.test(password)) errors.push(t('login.error.value.at-least-lowercase'))
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) errors.push(t('login.error.value.at-least-special-character'))

    return errors
}

export default passwordChecks
