import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'
import RetailparkContext from '@components/context/RetailparkContext'

const Logo = ({ className, linkDisabled }: { className?: string; linkDisabled?: boolean }) => {
    const { t } = useTranslation('common')
    const { longName } = useContext(RetailparkContext)

    if (linkDisabled) {
        return (
            <div className={className}>
                <Image src="/images/logo-oasiz.png" width={120} height={44} layout="fixed" alt={longName} title={longName} />
            </div>
        )
    }

    return (
        <div className={className}>
            <Link href="/">
                <a>
                    <span className="sr-only">{t('layout.homepage')}</span>
                    <Image src="/images/logo-oasiz.png" width={120} height={44} layout="fixed" alt={longName} title={longName} />
                </a>
            </Link>
        </div>
    )
}

export default Logo
