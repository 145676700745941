import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import { useRouter } from 'next/router'

const languages = [
    {
        id: 1,
        locale: 'es',
        name: 'Español',
        flag: '/images/ES.svg',
    },
    {
        id: 2,
        locale: 'en',
        name: 'English',
        flag: '/images/EN.svg',
    },
    {
        id: 2,
        locale: 'fr',
        name: 'Français',
        flag: '/images/FR.svg',
    },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Language = () => {
    const router = useRouter()
    const { pathname, asPath, query, locale } = router

    const [selected, setSelected] = useState(languages.find((language) => language.locale === locale) ?? languages[0])

    useEffect(() => {
        if (selected.locale !== locale) {
            router.push({ pathname, query }, asPath, { locale: selected.locale }).finally()
        }
    }, [asPath, locale, pathname, query, router, selected])

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="mt-6 md:mt-3 relative w-48 md:w-auto mx-auto">
                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-12 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                            <span className="flex items-center">
                                <Image src={`${selected.flag}`} alt={selected.name} width="16" height="16" className="shrink-0" />
                                <span className="ml-3 block truncate">{selected.name}</span>
                            </span>
                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {languages.map((language) => (
                                    <Listbox.Option key={language.name} className={({ active }) => classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')} value={language}>
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <Image src={`${language.flag}`} alt={language.name} width="16" height="16" className="shrink-0" />
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate capitalize')}>{language.name}</span>
                                                </div>

                                                {selected ? (
                                                    <span className={classNames(active ? 'text-white' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default Language
