export function isEmpty(state: any) {
    if (!state.first_name.value.replace(/\s+/, '').length) state.first_name.isError = true
    if (!state.last_name.value.replace(/\s+/, '').length) state.last_name.isError = true
    if (!state.email.value.replace(/\s+/, '').length) state.email.isError = true
    if (!state.postcode.value.replace(/\s+/, '').length) state.postcode.isError = true
    if (!state.password.value.replace(/\s+/, '').length) state.password.isError = true
    if (!state.privacy.value) state.privacy.isError = true
    return !!(state.first_name.isError || state.last_name.isError || state.email.isError || state.postcode.isError || state.password.isError || state.privacy.isError)
}

export function isValid(name: string, value: any): boolean {
    const specialChar = '#|\'<>^*()%!$&+,:;=?À-ȕ§°/¨^£`•“‘{¶«¡}®†ºπ€‡∂ﬁ¬µ‹≈©◊~∞…≠‚™ª∏¥Ω∑∆·ﬂ≥›⁄¢√∫¿±≤´„”’»"]'
    const nameRegex = '[@_0-9' + specialChar
    const emailRegex = '[' + specialChar
    const postcodeRegex = '[a-z-A-Z-@' + specialChar

    switch (name) {
        case 'first_name':
        case 'last_name':
            return value.match(nameRegex) === null

        case 'email':
            return value.match(emailRegex) === null

        case 'postcode':
            return value.match(postcodeRegex) === null && value.length <= 5

        case 'privacy':
        case 'channel_email':
        case 'channel_sms':
            return typeof value == 'boolean'

        case 'password':
        case 'gender':
        case 'date_of_birth':
        case 'address':
        case 'city':
        case 'phone':
            return true

        default:
            return false
    }
}
