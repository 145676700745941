import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'
import { Popover } from '@headlessui/react'
import LoginContext from '@components/context/LoginContext'

const PopoverAccount = () => {
    const { t } = useTranslation('common')
    const { handleButtonLogin } = useContext(LoginContext)

    return (
        <Popover.Button as="div">
            <button onClick={() => handleButtonLogin({ toAccount: true })} className="flex w-full">
                <span className="w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-dark">{t('layout.my-account')}</span>
            </button>
        </Popover.Button>
    )
}

export default PopoverAccount
