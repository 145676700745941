const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')

module.exports = {
    content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
    theme: {
        maskImage: {
            'blob-1': "url('/images/blobs/blob-1.svg')",
            'blob-2': "url('/images/blobs/blob-2.svg')",
            'blob-3': "url('/images/blobs/blob-3.svg')",
        },
        extend: {
            colors: {
                primary: {
                    light: '#51b168',
                    DEFAULT: '#387D49',
                    dark: '#20482a',
                },
                gold: '#B39547',
                current: 'currentColor',
            },
            fontFamily: {
                sans: ['Barlow', ...defaultTheme.fontFamily.sans],
                hof: ['"Hall Of Fun"', ...defaultTheme.fontFamily.serif],
                'hof-clean': ['"Hall Of Fun Clean"', ...defaultTheme.fontFamily.serif],
            },
            boxShadow: {
                't-md': '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
            },
            backgroundImage: {
                login: `url('${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/pages/bg-login.png')`,
                'i-shape': "url('/images/i-shape.png')",
                'homepage-hero': "url('/images/homepage-hero.jpg')",
                'leaf-left': `url('${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/leafs/leaf-left.png')`,
                'leaf-right': `url('${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/leafs/leaf-right.png')`,
                palm: `url('${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/leafs/palm.png')`,
                'double-palm': `url('${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/leafs/double-palm.png')`,
            },
            backgroundPosition: {
                'top-20': 'center top 5rem',
            },
            minHeight: {
                14: '56px',
                16: '64px',
                96: '384px',
                120: '480px',
            },
            height: {
                100: '400px',
                120: '480px',
                180: '720px',
            },
            width: {
                100: '400px',
                120: '480px',
            },
            typography: (theme) => ({
                DEFAULT: {
                    css: {
                        a: {
                            color: theme('colors.primary.DEFAULT'),
                            '&:hover': {
                                color: theme('colors.primary.light'),
                            },
                        },
                        h1: {
                            fontSize: '1.8rem',
                            fontWeight: 600,
                        },
                        h2: {
                            fontSize: '1.6rem',
                            fontWeight: 600,
                        },
                    },
                },
            }),
            keyframes: {
                animate: {
                    '0%': {
                        opacity: 0,
                        transform: 'translate(0px, -20px)',
                    },
                    '50%': {
                        opacity: 0.8,
                    },
                    '100%': {
                        opacity: 0,
                        transform: 'translate(0px, 20px)',
                    },
                },
            },
            animation: {
                chevron: 'animate 3s infinite',
            },
        },
    },
    plugins: [
        require('@tailwindcss/forms'),
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/typography'),
        plugin(function ({ matchUtilities, theme }) {
            matchUtilities({ mask: (value) => ({ maskImage: value }) }, { values: theme('maskImage') })
        }),
    ],
}
