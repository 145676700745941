import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import TransLink from '@components/elements/TransLink'
import { coniqPrivacyURL, coniqTermsURL } from '@utils/coniqFormHash'

const AgreementInput = ({ handleCheckChange, state }: { handleCheckChange: (checked: boolean, name: string) => void; state: any }) => {
    const { t, lang } = useTranslation('common')

    return (
        <div className="flex">
            <input
                onChange={(e) => handleCheckChange(e.target.checked, state.name)}
                id={state.name}
                name={state.name}
                type={state.type}
                checked={state.value}
                className={`${state.isError ? 'border-2 border-red-500' : 'border-gray-300'} h-4 w-4 text-primary focus:ring-primary rounded`}
            />
            {state.name === 'privacy' ? (
                <label htmlFor="privacy" className="ml-2 text-sm text-gray-800 text-left font-light">
                    <Trans i18nKey="common:login.accept-conditions" components={[<TransLink key="terms-conditions" href={coniqTermsURL(lang)} />, <TransLink key="privacy-policy" blank href={coniqPrivacyURL(lang)} />]} />
                    {state.required && <span className="text-red-800">*</span>}
                </label>
            ) : (
                <label htmlFor={state.name} className="ml-2 text-sm text-gray-800 text-left font-light">
                    {t(state.label)}
                </label>
            )}
        </div>
    )
}

export default AgreementInput
