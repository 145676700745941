import { createContext, Dispatch } from 'react'
import { initialLoginState, LoginAction, LoginState } from '@utils/loginReducer'

export type LoginContextProps = {
    state: LoginState
    dispatch: Dispatch<LoginAction>
    handleButtonLogin: ({ toAccount }: { toAccount: boolean }) => Promise<boolean | void>
}

const LoginContext = createContext<LoginContextProps>({
    state: initialLoginState,
    dispatch: () => null,
    handleButtonLogin: async () => void 0,
})

export default LoginContext
