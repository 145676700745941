import Footer from '@components/layout/footer/Footer'
import LoginContainer from '@components/account/login/LoginContainer'
import Modal from '@components/account/login/Modal'
import Header from '@components/layout/header/Header'
import Routes from '@components/layout/Routes'

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    return (
        <Routes>
            {(pageTabs) => (
                <div className="flex flex-col h-screen">
                    <Header pageTabs={pageTabs} />
                    <main>{children}</main>
                    <Footer pageTabs={pageTabs} />
                    <Modal>
                        <LoginContainer />
                    </Modal>
                </div>
            )}
        </Routes>
    )
}

export default Layout
