import { XCircleIcon } from '@heroicons/react/solid'

const FormError = ({ error }: { error: string | Array<string> | null }) => {
    if (error) {
        return (
            <div className="rounded-md bg-red-50 p-4 my-4">
                <div className="flex">
                    <div className="shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>

                    {Array.isArray(error) ? (
                        <div className="block">
                            <h5 className="ml-3 space-y-1.5 text-left text-sm text-red-800 font-medium">{error[0]}</h5>
                            {error.slice(1).map((message, index) => (
                                <p key={index} className="ml-3 space-y-1.5 text-left text-sm text-red-800 font-normal">
                                    {message}
                                </p>
                            ))}
                        </div>
                    ) : (
                        <h5 className="ml-3 space-y-1.5 text-left text-sm text-red-800 font-medium">{error}</h5>
                    )}
                </div>
            </div>
        )
    }

    return <></>
}

export default FormError
