
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppProps } from 'next/app'
import Layout from '@components/layout/Layout'
import AuthWrapper from '@components/layout/AuthWrapper'
import PlausibleProvider from 'next-plausible'
import NextNProgress from 'nextjs-progressbar'
import 'globals.css'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'
import { TailwindConfig } from 'tailwindcss/tailwind-config'
import { NextPage } from 'next'
import { ReactElement } from 'react'
import 'intl-pluralrules'
import RetailparkContext from '@components/context/RetailparkContext'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactElement<any, any> | null
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const { theme }: any = resolveConfig(tailwindConfig as unknown as TailwindConfig)

    const getLayout = Component.getLayout

    //@ts-ignore
    const { retailPark } = pageProps

    return (
        <PlausibleProvider domain={process.env.NEXT_PUBLIC_VERCEL_DOMAIN || ''} trackOutboundLinks={true}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}>
                <AuthWrapper>
                    <RetailparkContext.Provider value={retailPark}>
                        <NextNProgress color={theme.colors.primary.DEFAULT ?? '#8c8c8c'} height={1.5} options={{ showSpinner: false }} />
                        {getLayout ? (
                            getLayout(<Component {...pageProps} />)
                        ) : (
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        )}
                    </RetailparkContext.Provider>
                </AuthWrapper>
            </GoogleReCaptchaProvider>
        </PlausibleProvider>
    )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  