import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Panel from '@components/layout/header/Panel'
import PopoverOpenButton from '@components/layout/header/PopoverOpenButton'
import Tabs from '@components/layout/header/Tabs'
import AccountButton from '@components/layout/header/AccountButton'
import Logo from '@components/layout/Logo'
import SocialIcons from '@components/layout/SocialIcons'
import { PageTab } from '@components/layout/Routes'

const Header = ({ pageTabs }: { pageTabs: Array<PageTab> }) => {
    return (
        <header className="relative z-20 bg-white shadow-md">
            <Popover>
                <Popover.Overlay className="z-10 fixed inset-0 bg-black opacity-40" />

                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between items-center py-4 lg:justify-start lg:space-x-10">
                        <div className="flex min-w-min relative top-1 justify-start lg:w-0 lg:flex-1">
                            <Logo />
                        </div>
                        <div className="-mr-2 -my-2 lg:hidden">
                            <PopoverOpenButton />
                        </div>
                        <div className="hidden lg:flex">
                            <Tabs tabs={pageTabs} />
                        </div>
                        <div className="hidden lg:flex lg:flex-1 items-center justify-end space-x-2">
                            <div className="inline-flex space-x-3 px-4">
                                <SocialIcons className="w-5" />
                            </div>
                            <AccountButton />
                        </div>
                    </div>
                </div>

                <Transition as={Fragment} enter="duration-200 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-200 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                    <Popover.Panel focus className="z-10 absolute top-0 inset-x-0 p-2 transition origin-top-right lg:hidden">
                        {({ close }) => <Panel tabs={pageTabs} close={close} />}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </header>
    )
}

export default Header
