import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useState } from 'react'
import LoginContext from '@components/context/LoginContext'
import { useRouter } from 'next/router'

const AccountButton = () => {
    const { t } = useTranslation('common')
    const { asPath } = useRouter()
    const { handleButtonLogin } = useContext(LoginContext)
    const [isAccount, setIsAccount] = useState<boolean>(false)

    useEffect(() => {
        setIsAccount(asPath.includes(t('routes.account')))
    }, [asPath, t])

    if (isAccount) {
        return <div className="flex-none items-center px-3 py-1.5 border-2 border-primary shadow-sm text-xs uppercase leading-4 font-medium rounded text-white bg-primary">{t('layout.my-account')}</div>
    }

    return (
        <button
            onClick={() => handleButtonLogin({ toAccount: true })}
            className="flex-none items-center px-3 py-1.5 border-2 border-primary shadow-sm text-xs uppercase leading-4 font-medium rounded text-primary bg-white hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            {t('layout.my-account')}
        </button>
    )
}
export default AccountButton
