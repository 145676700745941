const coniqFormHash = (lang: string): string => {
    if (lang === 'es' && process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_ES) return process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_ES
    else if (lang === 'en' && process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_EN) return process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_EN
    else if (process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_FR) return process.env.NEXT_PUBLIC_CONIQ_HASH_WEB_SIGNUP_FR
    else return ''
}

export const coniqReferralHash = (): string => {
    return process.env.NEXT_PUBLIC_CONIQ_HASH_REFERRAL_SIGNUP_ES || ''
}

export const coniqTermsURL = (lang: string): string => {
    let hash = ''

    switch (lang) {
        case 'es':
            hash = '331/ES.html'
            break
        case 'en':
            hash = '313/EN.html'
            break
        case 'fr':
            hash = '338/FR.html'
    }

    return `https://poweredby.coniq.com/policy/${hash}`
}

export const coniqPrivacyURL = (lang: string): string => {
    let hash = ''

    switch (lang) {
        case 'es':
            hash = '332/ES.html'
            break
        case 'en':
            hash = '332/ES.html'
            break
        case 'fr':
            hash = '337/FR.html'
    }

    return `https://poweredby.coniq.com/policy/${hash}`
}

export default coniqFormHash
