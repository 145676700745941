import { useState } from 'react'
import ModalCloseButton from '@components/account/login/ModalCloseButton'
import SigninForm from '@components/account/login/SigninForm'
import RegisterForm from '@components/account/login/RegisterForm'
import ResetPasswordForm from '@components/account/login/ResetPasswordForm'
import { createLocalStorageStateHook } from 'use-local-storage-state'

export const LoginForm = {
    Signin: 'Signin',
    Register: 'Register',
    Reset: 'Reset',
}

export const useHasAccount = createLocalStorageStateHook('hasAccount', false)

const LoginContainer = () => {
    const [hasAccount] = useHasAccount()
    const [loginForm, setLoginForm] = useState<typeof LoginForm[keyof typeof LoginForm]>(hasAccount ? LoginForm.Signin : LoginForm.Register)

    return (
        <div tabIndex={0} className="flex flex-col lg:flex-row items-center lg:bg-login bg-left bg-no-repeat shadow-xl rounded focus:outline-none overflow-hidden">
            <div className="w-1/2" />
            <div className="w-full lg:w-1/2 flex flex-col bg-white relative">
                <ModalCloseButton />
                {loginForm === LoginForm.Reset ? <ResetPasswordForm setLoginForm={setLoginForm} /> : loginForm === LoginForm.Register ? <RegisterForm setLoginForm={setLoginForm} /> : <SigninForm setLoginForm={setLoginForm} />}
            </div>
        </div>
    )
}

export default LoginContainer
