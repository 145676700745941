export type LoginState = {
    openLogin: boolean
    isLogged: boolean
    customer?: Customer | null
    redirectToAccount: boolean
}

export const initialLoginState: LoginState = {
    openLogin: false,
    isLogged: false,
    customer: null,
    redirectToAccount: false,
}

export const LoginActions = {
    Initial: 'Initial',
    Login: 'Login',
    LoggedIn: 'LoggedIn',
    LoggedOut: 'LoggedOut',
}

export type LoginAction = { type: typeof LoginActions[keyof typeof LoginActions]; customer?: Customer | null; redirectToAccount?: boolean }

export default function loginReducer(state: LoginState, action: LoginAction) {
    switch (action.type) {
        case LoginActions.Initial: {
            return {
                ...state,
                openLogin: false,
                redirectToAccount: false,
            }
        }
        case LoginActions.Login: {
            return {
                ...state,
                openLogin: true,
                redirectToAccount: action.redirectToAccount ?? state.redirectToAccount,
            }
        }
        case LoginActions.LoggedIn: {
            return {
                ...state,
                isLogged: true,
                openLogin: false,
                customer: action.customer,
                redirectToAccount: action.redirectToAccount ?? state.redirectToAccount,
            }
        }
        case LoginActions.LoggedOut: {
            return {
                ...state,
                isLogged: false,
                openLogin: false,
                customer: null,
                redirectToAccount: false,
            }
        }
        default:
            return state
    }
}
