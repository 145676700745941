import { PageTab } from '@components/layout/Routes'
import Link from 'next/link'
import { useRouter } from 'next/router'

const PopoverTabs = ({ tabs, close }: { tabs: Array<PageTab>; close: (ref?: HTMLElement) => void }) => {
    const { asPath } = useRouter()

    return (
        <nav className="grid gap-y-8 mt-8">
            {tabs.map((tab) => (
                <Link key={tab.route} href={`/${tab.route}`}>
                    <a className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50" onClick={() => close()}>
                        <tab.icon className={`${asPath.includes(tab.route) ? 'text-primary' : 'text-primary-dark'} shrink-0 h-6 w-6`} aria-hidden="true" />
                        <span className={`${asPath.includes(tab.route) ? 'text-primary' : 'text-gray-900'} ml-3 text-base font-medium`}>{tab.title}</span>
                    </a>
                </Link>
            ))}
        </nav>
    )
}

export default PopoverTabs
