import { Dispatch, FormEvent, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { LoginForm } from '@components/account/login/LoginContainer'
import useTranslation from 'next-translate/useTranslation'
import LoginContext from '@components/context/LoginContext'
import { LoginActions } from '@utils/loginReducer'
import Logo from '@components/layout/Logo'
import LoginTitle from '@components/account/login/LoginTitle'
import FormError from '@components/account/login/FormError'
import ButtonLoading from '@components/account/login/ButtonLoading'
import ButtonSubmit from '@components/account/login/ButtonSubmit'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { createLocalStorageStateHook } from 'use-local-storage-state'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export const useRememberedUser = createLocalStorageStateHook<string>('rememberMe', '')

const SigninForm = ({ setLoginForm }: { setLoginForm: Dispatch<SetStateAction<typeof LoginForm[keyof typeof LoginForm]>> }) => {
    const { dispatch } = useContext(LoginContext)
    const { t } = useTranslation('common')
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [rememberedUser, setRememberedUser] = useRememberedUser()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | Array<string> | null>(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const [username, setUsername] = useState<string>(rememberedUser || '')
    const [password, setPassword] = useState<string>('')
    const [rememberMe, setRememberMe] = useState<boolean>(!!rememberedUser)

    useEffect(() => setIsLoading(false), [error])

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) return null
        return await executeRecaptcha('login')
    }, [executeRecaptcha])

    const handleSigninForm = async (event: FormEvent): Promise<void> => {
        event.preventDefault()

        setIsLoading(true)

        if (!username.length || !password.length) return setError(t('login.error.missing-credentials'))
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) return setError(t('login.error.wrong-email'))

        setRememberedUser(rememberMe ? String(username) : '')

        if (await handleReCaptchaVerify()) {
            const signinResponse = await fetch('/api/signin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username,
                    password,
                }),
            })

            if (signinResponse.ok) {
                const customerResponse = await fetch('/api/customer')
                const customerResult = customerResponse.ok ? await customerResponse.json() : null

                dispatch({
                    type: LoginActions.LoggedIn,
                    customer: customerResult.customer,
                })
            } else {
                const signinResult = await signinResponse.json()
                setError(signinResult.message.includes('400') ? [t('login.error.wrong-credentials'), t('login.error.please-reset')] : t('login.error.network'))
            }
        } else {
            setError(t('login.error.general'))
        }
    }

    return (
        <div className="w-full sm:w-96 my-16 lg:my-24 mx-auto px-8 sm:px-0 space-y-8">
            <Logo className="pb-2" linkDisabled />

            <LoginTitle title={t('login.login-title')} />

            {/* <LoginSocial /> */}
            <form onSubmit={handleSigninForm} className="space-y-5">
                <div className="space-y-3">
                    <div className="space-y-1">
                        <label htmlFor="email" className="block text-gray-700 text-left">
                            Email
                        </label>
                        <input
                            onChange={(e) => {
                                setError(null)
                                setUsername(e.target.value)
                            }}
                            defaultValue={username}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                    </div>

                    <div className="space-y-1">
                        <label htmlFor="password" className="block text-gray-700 text-left">
                            {t('input.password')}
                        </label>
                        <div className="relative">
                            <input
                                onChange={(e) => {
                                    setError(null)
                                    setPassword(e.target.value)
                                }}
                                defaultValue={password}
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            />
                            {password && showPassword ? (
                                <div onClick={() => setShowPassword(false)} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                                    <EyeIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                                </div>
                            ) : password ? (
                                <div onClick={() => setShowPassword(true)} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                                    <EyeOffIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                {error && <FormError error={error} />}

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input onChange={(e) => setRememberMe(e.target.checked)} checked={rememberMe} id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded" />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                            {t('login.remember-me')}
                        </label>
                    </div>

                    <div className="text-sm">
                        <div onClick={() => setLoginForm(LoginForm.Reset)} className="font-medium text-primary-dark hover:text-primary cursor-pointer">
                            {t('login.wants-reset')}
                        </div>
                    </div>
                </div>

                <div>{isLoading ? <ButtonLoading value={`${t('login.loading-signin')}`} /> : <ButtonSubmit value={t('login.submit-signin')} disabled={!username.length || !password.length} />}</div>

                <div className="pt-4">
                    <button onClick={() => setLoginForm(LoginForm.Register)} className="text-sm font-medium text-primary-dark hover:text-primary uppercase">
                        {t('login.wants-register')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SigninForm
