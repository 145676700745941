import { ReactElement } from 'react'
import Link from 'next/link'

const TransLink = ({ href, blank, children }: { href: string; blank?: boolean; children?: ReactElement }) => {
    return (
        <Link href={href || ''}>
            <a target={href.includes('http') || blank ? '_blank' : '_self'} className="font-medium text-primary hover:text-primary-light">
                {children}
            </a>
        </Link>
    )
}

export default TransLink
