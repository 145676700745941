import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react'
import { LoginForm } from '@components/account/login/LoginContainer'
import useTranslation from 'next-translate/useTranslation'
import { CheckCircleIcon, MailIcon } from '@heroicons/react/solid'
import Logo from '@components/layout/Logo'
import LoginTitle from '@components/account/login/LoginTitle'
import FormError from '@components/account/login/FormError'
import ButtonLoading from '@components/account/login/ButtonLoading'
import ButtonSubmit from '@components/account/login/ButtonSubmit'
import { useRememberedUser } from '@components/account/login/SigninForm'

const ResetPasswordForm = ({ setLoginForm }: { setLoginForm: Dispatch<SetStateAction<typeof LoginForm[keyof typeof LoginForm]>> }) => {
    const { t } = useTranslation('common')

    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    const [rememberedUser] = useRememberedUser()
    const [username, setUsername] = useState<string>(rememberedUser || '')

    useEffect(() => setIsLoading(false), [error])

    const handleResetPasswordForm = async (event: FormEvent): Promise<void> => {
        event.preventDefault()

        setIsLoading(true)

        if (!username.length) {
            setError(t('login.error.no-email'))
            return void 0
        }

        const response = await fetch('/api/reset_password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
            }),
        })

        if (response.ok) {
            setIsSuccess(true)
        } else {
            setError(t('login.error.network'))
        }
    }

    return (
        <div className="w-full sm:w-96 my-16 lg:my-24 mx-auto px-8 sm:px-0 space-y-8">
            <Logo className="pb-2" linkDisabled />

            <LoginTitle title={t('login.reset-my-password')} />

            <form onSubmit={handleResetPasswordForm} className="space-y-5">
                <div>
                    <label htmlFor="email" className="block text-gray-700 text-left">
                        Email
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            onChange={(e) => {
                                setError(null)
                                setUsername(e.target.value)
                            }}
                            defaultValue={username}
                            disabled={isSuccess}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className={`${isSuccess && 'bg-gray-50 text-gray-400'} block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-primary focus:border-primary`}
                        />
                    </div>
                </div>

                {error && !isSuccess && <FormError error={error} />}

                {isSuccess ? (
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3 space-y-1.5 text-left text-sm text-green-800">
                                <h5 className="font-medium">{t('login.success-reset.title')}</h5>
                                <p>{t('login.success-reset.message')}</p>
                            </div>
                        </div>
                    </div>
                ) : isLoading ? (
                    <ButtonLoading value={`${t('login.loading-reset')}...`} />
                ) : (
                    <ButtonSubmit value={t('login.submit-reset')} disabled={!username.length} />
                )}

                <div className="pt-4">
                    <button onClick={() => setLoginForm(LoginForm.Signin)} className="text-sm font-medium text-primary-dark hover:text-primary uppercase">
                        {t('login.wants-signin.from-reset')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ResetPasswordForm
