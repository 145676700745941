import { useContext } from 'react'
import RetailparkContext from '@components/context/RetailparkContext'

const Footnotes = () => {
    const { longName } = useContext(RetailparkContext)

    return (
        <p className="pt-0 md:pt-4 text-center text-sm text-gray-400">
            &copy; {new Date().getFullYear()} {longName}
        </p>
    )
}

export default Footnotes
