import { PageTab } from '@components/layout/Routes'
import Tabs from '@components/layout/footer/Tabs'
import Footnotes from '@components/layout/footer/Footnotes'
import Logo from '@components/layout/Logo'
import Sections from '@components/layout/footer/Sections'

const Footer = ({ pageTabs }: { pageTabs: Array<PageTab> }) => {
    return (
        <footer className="z-10 shadow-t-md bg-white">
            <div className="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8 space-y-8 md:space-y-4">
                <Logo className="flex justify-center" />
                <Tabs tabs={pageTabs} />
                <Sections />
                <Footnotes />
            </div>
        </footer>
    )
}

export default Footer
