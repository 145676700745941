import { ChangeEvent } from 'react'
import { ExclamationCircleIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import NumberFormat from 'react-number-format'
import useTranslation from 'next-translate/useTranslation'

export const RegisterInput = ({ handleInputChange, state }: { handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void; state: any }) => {
    const { t } = useTranslation('common')

    return (
        <div className={`relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary`}>
            <label htmlFor={state.name} className="absolute -top-2.5 left-2 -mt-px inline-block px-1 bg-white text-sm text-gray-600">
                {t(state.label)}
                {state.required && <span className="text-red-800"> *</span>}
            </label>
            <input
                onChange={(e) => handleInputChange && handleInputChange(e)}
                autoComplete={state.autoComplete ?? 'on'}
                value={state.value}
                type={state.type ?? 'text'}
                name={state.name}
                id={state.name}
                required={state.required ?? false}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            />
            {state.isError && state.required && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
        </div>
    )
}

export const InputPassword = ({ handleInputChange, handleShowPassword, state }: { handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void; handleShowPassword: (show: boolean) => void; state: any }) => {
    const { t } = useTranslation('common')

    return (
        <div className={`relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary`}>
            <label htmlFor={state.name} className="absolute -top-2.5 left-2 -mt-px inline-block px-1 bg-white text-sm text-gray-600">
                {t(state.label)}
                {state.required && <span className="text-red-800"> *</span>}
            </label>
            <input
                onChange={(e) => handleInputChange && handleInputChange(e)}
                autoComplete={state.autoComplete ?? 'on'}
                value={state.value}
                type={state.showPassword ? 'text' : 'password'}
                name={state.name}
                id={state.name}
                required={state.required ?? false}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            />
            {state.isError && state.required && (
                <div className="absolute inset-y-0 right-4 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
            {state.value === '' ? (
                <></>
            ) : (
                <div onClick={() => handleShowPassword(!state.showPassword)} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    {state.showPassword ? <EyeIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /> : <EyeOffIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />}
                </div>
            )}
        </div>
    )
}

export const InputBirthday = ({ handleInputDateChange, state }: { handleInputDateChange?: (date: string) => void; state: any }) => {
    const { t } = useTranslation('common')

    return (
        <div className={`relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary`}>
            <label htmlFor={state.name} className="absolute -top-2.5 left-2 -mt-px inline-block px-1 bg-white text-sm text-gray-600">
                {t(state.label)}
                {state.required && <span className="text-red-800"> *</span>}
            </label>
            <NumberFormat
                onValueChange={({ formattedValue }) => {
                    handleInputDateChange && handleInputDateChange(formattedValue)
                }}
                value={state.value}
                required={state.required ?? true}
                format="##/##/####"
                placeholder="DD/MM/YYYY"
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            />
        </div>
    )
}
