import { PageTab } from '@components/layout/Routes'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useContext } from 'react'
import LoginContext from '@components/context/LoginContext'

const Tabs = ({ tabs }: { tabs: Array<PageTab> }) => {
    const { t } = useTranslation('common')
    const { handleButtonLogin } = useContext(LoginContext)

    return (
        <nav className="flex flex-wrap justify-center border-t border-b" aria-label="Footer">
            {tabs.map((tab) => (
                <div key={tab.route} className="px-5 py-2">
                    <Link href={`/${tab.route}`}>
                        <a className="uppercase text-md md:text-sm text-gray-800 font-normal hover:text-primary hover:font-medium">{tab.title}</a>
                    </Link>
                </div>
            ))}

            <button onClick={() => handleButtonLogin({ toAccount: true })} className="px-5 py-2">
                <span className="uppercase text-md md:text-sm text-gray-800 font-normal hover:text-primary hover:font-medium">{t('layout.my-account')}</span>
            </button>
        </nav>
    )
}

export default Tabs
