import { Fragment, ReactElement, useContext, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LoginContext from '@components/context/LoginContext'
import { LoginActions } from '@utils/loginReducer'

const Modal = ({ children }: { children: ReactElement }) => {
    const { state, dispatch } = useContext(LoginContext)
    const focusRef = useRef(null)

    return (
        <Transition.Root show={state.openLogin} as={Fragment}>
            <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => dispatch({ type: LoginActions.Initial })} initialFocus={focusRef}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-40 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div ref={focusRef} className="inline-block align-bottom transform transition-all w-full max-w-7xl sm:align-middle px-2 sm:px-16 md:px-36 lg:px-12">
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Modal
