import { Dialog } from '@headlessui/react'

const LoginTitle = ({ title }: { title: string }) => {
    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-primary-light" />
            </div>
            <div className="relative flex justify-center">
                <Dialog.Title as="h1" className="px-3 bg-white text-sm font-medium text-gray-900 uppercase">
                    {title}
                </Dialog.Title>
            </div>
        </div>
    )
}

export default LoginTitle
