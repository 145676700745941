import useTranslation from 'next-translate/useTranslation'
import { ChangeEvent } from 'react'

const GenderInput = ({ handleInputChange, state }: { handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void; state: any }) => {
    const { t } = useTranslation('common')

    return (
        <div className="flex items-center space-x-6">
            <div className="flex-none text-sm text-gray-600 relative -top-0.5 ml-1">
                {t('input.sex')}
                {state.required && <span className="text-red-800"> *</span>} :
            </div>
            <div className="flex">
                <input onChange={(e) => handleInputChange(e)} checked={state.value === 'M'} id="M" value="M" name="gender" type="radio" className="focus:ring-primary-light h-4 w-4 text-primary border-gray-300" />
                <label htmlFor="M" className="ml-2 block text-sm">
                    {t('input.mr')}
                </label>
            </div>
            <div className="flex">
                <input onChange={(e) => handleInputChange(e)} checked={state.value === 'F'} id="F" value="F" name="gender" type="radio" className="focus:ring-primary-light h-4 w-4 text-primary border-gray-300" />
                <label htmlFor="F" className="ml-2 block text-sm">
                    {t('input.mrs')}
                </label>
            </div>
        </div>
    )
}

export default GenderInput
