import { LoginForm } from '@components/account/login/LoginContainer'
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import Logo from '@components/layout/Logo'
import LoginTitle from '@components/account/login/LoginTitle'
import GenderInput from '@components/account/login/elements/GenderInput'
import AgreementInput from '@components/account/login/elements/AgreementInput'
import { InputBirthday, InputPassword, RegisterInput } from './elements/RegisterInput'
import useTranslation from 'next-translate/useTranslation'
import { LoginActions } from '@utils/loginReducer'
import LoginContext from '@components/context/LoginContext'
import ButtonLoading from '@components/account/login/ButtonLoading'
import ButtonSubmit from '@components/account/login/ButtonSubmit'
import passwordChecks from '@utils/passwordChecks'
import FormError from '@components/account/login/FormError'
import coniqFormHash, { coniqReferralHash } from '@utils/coniqFormHash'
import { isEmpty } from '@utils/registerFormChecks'
import registerReducer, { initialRegisterState, RegisterActions } from '@utils/registerReducer'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import es from 'react-phone-input-2/lang/es.json'
import fr from 'react-phone-input-2/lang/fr.json'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useRouter } from 'next/router'

const RegisterForm = ({ setLoginForm }: { setLoginForm: Dispatch<SetStateAction<typeof LoginForm[keyof typeof LoginForm]>> }) => {
    const { t, lang } = useTranslation('common')
    const { dispatch } = useContext(LoginContext)
    const { executeRecaptcha } = useGoogleReCaptcha()
    const router = useRouter()

    const referral = router.query.referral || null

    const [error, setError] = useState<string | Array<string> | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)

    const [state, dispatchReducer] = useReducer(registerReducer, initialRegisterState)

    useEffect(() => setLoading(false), [error])

    const valuesChecks = (): boolean => {
        const passwordErrors = passwordChecks(state.password.value, t)
        if (passwordErrors.length) {
            setError([t('login.error.wrong-password'), ...passwordErrors])
            return false
        }

        if (state.email.value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email.value)) {
            setError(t('login.error.wrong-email'))
            return false
        }

        if (!state.privacy.value) {
            setError(t('login.error.should-accept-privacy'))
            return false
        }

        if (isEmpty(state)) {
            setError(t('login.error.missing-fields'))
            return false
        }

        return true
    }

    const coniqCall = async (): Promise<void> => {
        // Coniq API specs : Conversion of date fields to ISO 8601 format (yyyy-mm-dd)
        const date_of_birth = state.date_of_birth.value?.split('/').reverse().join('-')

        // Step 1 : check that email is not registered in Coniq.
        // If we don't check, Coniq will overwrite existing customer with the same email (even without password)
        const checkEmailCall = await fetch('/api/check_email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: state.email.value,
            }),
        })

        const resultCheckEmail = await checkEmailCall.json()
        if (!checkEmailCall.ok) return setError([t('login.error.general'), resultCheckEmail.message])
        if (resultCheckEmail.exists) return setError([t('login.error.existing-account'), t('login.error.please-connect')])

        // Step 2 : register submitted data.
        // In case of error, we split error messages into multiple paragraphs.
        const registerCall = await fetch('/api/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fields: {
                    first_name: state.first_name.value,
                    last_name: state.last_name.value,
                    address1: state.address.value,
                    city: state.city.value,
                    postcode: state.postcode.value,
                    email: state.email.value,
                    gender: state.gender.value,
                    date_of_birth,
                    phone: state.phone.value,
                    privacy_agreement: state.privacy.value,
                    marketing_agreement: state.channel_email.value || state.channel_sms.value,
                    marketing_channels: {
                        email: state.channel_email.value,
                        sms: state.channel_sms.value,
                    },
                },
                password: state.password.value,
                formHash: referral && referral.length > 0 ? coniqReferralHash() : coniqFormHash(lang),
                referral: referral,
            }),
        })

        if (!registerCall.ok) {
            const { message } = await registerCall.json()
            return setError(message.includes('401') ? [t('login.error.wrong-password'), ...message.split('|').splice(1)] : [t('login.error.general'), ...message.split('.')])
        }

        // Step 3 : if successful registration, the user is directly signed in.
        // If signin succeeds, user is routed to account page.
        const signinCall = await fetch('/api/signin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: state.email.value,
                password: state.password.value,
            }),
        })

        if (signinCall.ok) {
            const customerResponse = await fetch('/api/customer')
            const customerResult = customerResponse.ok ? await customerResponse.json() : null

            dispatch({
                type: LoginActions.LoggedIn,
                customer: customerResult.customer,
                redirectToAccount: true,
            })
        } else {
            const { message } = await signinCall.json()
            return setError(message)
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setError(null)
        dispatchReducer({ type: RegisterActions.UpdateForm, data: { name: e.target.name, value: e.target.value } })
    }
    const handleCheckChange = (checked: boolean, name: string): void => {
        setError(null)
        dispatchReducer({ type: RegisterActions.UpdateForm, data: { name: name, value: checked } })
    }
    const handleInputDateChange = (date: string): void => {
        setError(null)
        dispatchReducer({ type: RegisterActions.UpdateForm, data: { name: 'date_of_birth', value: date } })
    }
    const handleChangePhone = (value: string, country: CountryData): void => {
        setError(null)
        dispatchReducer({ type: RegisterActions.UpdateForm, data: { name: 'phone', value: { country_code: value.length ? country.dialCode : '', number: value.slice(country.dialCode.length) } } })
    }
    const handleShowPassword = (show: boolean): void => {
        dispatchReducer({ type: RegisterActions.ShowPassword, data: { name: 'password', showPassword: show } })
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) return null
        return await executeRecaptcha('login')
    }, [executeRecaptcha])

    const handleRegisterForm = async (event: FormEvent): Promise<void> => {
        event.preventDefault()

        setLoading(true)
        if (valuesChecks() && (await handleReCaptchaVerify())) await coniqCall()
        setLoading(false)
    }

    return (
        <div className="w-full sm:w-96 my-12 mx-auto px-8 sm:px-0 space-y-6">
            <Logo className="pb-2" linkDisabled />
            <LoginTitle title={t('login.create-my-account')} />
            <form onSubmit={handleRegisterForm}>
                <div className="grid sm:grid-cols-2 gap-x-4 gap-y-6">
                    <RegisterInput state={state.first_name} handleInputChange={handleInputChange} />
                    <RegisterInput state={state.last_name} handleInputChange={handleInputChange} />
                    <RegisterInput state={state.email} handleInputChange={handleInputChange} />
                    <InputPassword state={state.password} handleInputChange={handleInputChange} handleShowPassword={handleShowPassword} />
                    <RegisterInput state={state.address} handleInputChange={handleInputChange} />
                    <RegisterInput state={state.city} handleInputChange={handleInputChange} />
                    <RegisterInput state={state.postcode} handleInputChange={handleInputChange} />
                    <InputBirthday state={state.date_of_birth} handleInputDateChange={handleInputDateChange} />
                    <PhoneInput
                        specialLabel={t('input.phone')}
                        country={['es', 'fr'].includes(lang) ? lang : 'fr'}
                        onChange={(value, country) => handleChangePhone(value, country as CountryData)}
                        preferredCountries={['es', 'fr']}
                        localization={lang === 'es' ? es : lang === 'fr' ? fr : undefined}
                    />
                    <GenderInput handleInputChange={handleInputChange} state={state.gender} />
                </div>

                <div className="space-y-2 py-2 lg:py-0 lg:mt-5 lg:mb-5">
                    <div className="text-sm text-left text-gray-800 font-light">{t('login.accept-marketing')}</div>
                    <AgreementInput state={state.channel_email} handleCheckChange={handleCheckChange} />
                    <AgreementInput state={state.channel_sms} handleCheckChange={handleCheckChange} />
                    <AgreementInput state={state.privacy} handleCheckChange={handleCheckChange} />
                </div>
                {error && <FormError error={error} />}
                {isLoading ? <ButtonLoading value={t('login.loading-register')} /> : <ButtonSubmit value={t('login.submit-register')} />}
                <button onClick={() => setLoginForm(LoginForm.Signin)} className="mt-5 pt-2 text-sm font-medium text-primary-dark hover:text-primary uppercase">
                    {t('login.wants-signin.from-register')}
                </button>
            </form>
        </div>
    )
}

export default RegisterForm
