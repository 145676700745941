const ButtonSubmit = ({ value, disabled }: { value: string; disabled?: boolean }) => {
    return (
        <button
            type="submit"
            disabled={disabled ?? false}
            className={`${
                disabled && 'cursor-not-allowed'
            } w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary uppercase`}>
            {value}
        </button>
    )
}

export default ButtonSubmit
