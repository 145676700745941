import { XIcon } from '@heroicons/react/outline'
import { useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import LoginContext from '@components/context/LoginContext'
import { LoginActions } from '@utils/loginReducer'

const ModalCloseButton = () => {
    const { dispatch } = useContext(LoginContext)
    const { t } = useTranslation('common')

    return (
        <div className="absolute top-0 right-0 pt-4 pr-4">
            <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" onClick={() => dispatch({ type: LoginActions.Initial })}>
                <span className="sr-only">{t('close-modal')}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
    )
}

export default ModalCloseButton
