import { isValid } from '@utils/registerFormChecks'

export const RegisterActions = {
    UpdateForm: 'UpdateForm',
    ShowPassword: 'ShowPassword',
}

export type RegisterAction = {
    type: typeof RegisterActions[keyof typeof RegisterActions]
    data: {
        name: string
        value?: unknown
        showPassword?: boolean
    }
}

export type RegisterFields = {
    gender: 'M' | 'F'
    first_name: string | null
    last_name: string | null
    email: string | null
    address: string | null
    city: string | null
    postcode: string | null
    date_of_birth: string | null
    phone: { country_code: string; number: string }
}

export const initialRegisterState = {
    gender: {
        value: 'M',
        required: true,
    },
    first_name: {
        name: 'first_name',
        label: 'input.first-name',
        autoComplete: 'given-name',
        required: true,
        value: '',
        isError: false,
    },
    last_name: {
        name: 'last_name',
        label: 'input.last-name',
        autoComplete: 'family-name',
        required: true,
        value: '',
        isError: false,
    },
    email: {
        name: 'email',
        label: 'input.email',
        type: 'email',
        autoComplete: 'email',
        required: true,
        value: '',
        isError: false,
    },
    address: {
        name: 'address',
        label: 'input.address_1',
        autoComplete: 'street-address',
        required: false,
        value: '',
        isError: false,
    },
    city: {
        name: 'city',
        label: 'input.city',
        autoComplete: 'address-level2',
        required: false,
        value: '',
        isError: false,
    },
    postcode: {
        name: 'postcode',
        label: 'input.postcode',
        autoComplete: 'postal-code',
        required: true,
        value: '',
        isError: false,
    },
    password: {
        name: 'password',
        label: 'input.password',
        autoComplete: 'new-password',
        required: true,
        showPassword: false,
        value: '',
        isError: false,
    },
    date_of_birth: {
        name: 'date_of_birth',
        label: 'input.birthdate',
        required: true,
        value: '',
    },
    phone: {
        name: 'phone',
        label: 'input.phone',
        required: false,
        value: { country_code: '', number: '' },
    },
    channel_email: {
        name: 'channel_email',
        type: 'checkbox',
        label: 'input.email',
        required: false,
        value: false,
    },
    channel_sms: {
        name: 'channel_sms',
        type: 'checkbox',
        label: 'input.sms',
        required: false,
        value: false,
    },
    privacy: {
        name: 'privacy',
        type: 'checkbox',
        label: 'login.accept-conditions',
        required: true,
        value: false,
        isError: false,
    },
}

function registerReducer(state: any, action: RegisterAction) {
    const { name, value, showPassword } = action.data

    switch (action.type) {
        case RegisterActions.UpdateForm:
            if (isValid(name, value)) return { ...state, [name]: { ...state[name], value, isError: false } }
            else return state
        case RegisterActions.ShowPassword:
            return { ...state, [name]: { ...state[name], showPassword } }
        default:
            return state
    }
}

export default registerReducer
